import { useCompanyConfig } from "@/hooks/useCompanyConfig";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { callResultLabels } from "@/models/CallHistory";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { RootState } from "@/store/store";
import { useTheme } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { request } from "@/models/telai-backend/client";
import {
  CallOutlined,
  HistoryToggleOffOutlined,
  PersonOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller,   useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import RedialAccordion from "./RedialAccordion";
import { AiNurturingList, RedialContact } from "@/models/Call";

const columns: GridColDef[] = [
  { field: "name", headerName: "会社名", width: 150 },
  // { field: "phoneNumber", headerName: "電話番号", width: 100 },
  { field: "callNoteResult", headerName: "コール結果", width: 160 },
  { field: "createdAt", headerName: "通話日付", width: 120 },
  // { field: "nextCallDate", headerName: "次回架電予定", width: 120 },
  { field: "operatorId", headerName: "担当者", width: 120 },
  { field: "callCount", headerName: "再コール回数", width: 120 },
] as const;

const RedialAi = React.memo(
  ({
    nurturingList,
    companyPhoneNumbers,
    fetchContactList,
  }: {
    nurturingList: AiNurturingList;
    companyPhoneNumbers: string[];
    fetchContactList: (contactListId:string, scriptId:string,limit:number, offset:number, sortField:string, sort:"asc" | "desc" ) => Promise<RedialContact>;
  }) => {
    const dispatch = useDispatch();
    const companyConfig = useCompanyConfig();
    const users = useCompanyUsers();
    const theme = useTheme();
    const waitingUsers = useMemo(
      () =>
        Object.values(users).filter((user) => {
          // user.role === "USER" &&
          return users[user.id]?.status === "FREE" && user.online;
        }),
      [users],
    );
    const roles = useSelector(
      (state: RootState) => state.user.loggedInUser.roles,
    );

    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedRedialGroupKey, setSelectedCallGroup] = useState<
      string | null
    >(null);
    const [phoneNumber, setPhoneNumber] = useState("");

    // form validation
    const schema = useMemo(
      () =>
        yup.object({
          callAiNum: yup
            .number()
            .typeError("数値のみ有効です。")
            .min(1, "1以上の値を指定してください。")
            .max(
              selectedUsers.length ? selectedUsers.length : 99999,
              "ユーザー数以下の値を指定してください。",
            )
            .required("同時稼働AI数は必須です。"),
        }),
      [selectedUsers],
    );

    const {
      formState: { errors },
      control,
      reset
    } = useForm({
      mode: "all",
      criteriaMode: "all",
      defaultValues: {
        callAiNum: 0,
      },
      shouldFocusError: false,
      resolver: yupResolver(schema),
    });

    const [formValues, setFormValues] = useState({
      callAiNum: 0,
    });

    const handleFormChange = useCallback(
      (key: string, value: string | number) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          [key]: value,
        }));
      },
      [],
    );

    // AIによるナーチャリングかつ、callListIdとscriptIdが一致するものをグループ化
    const [aiRedialGroups, setAiRedialGroups] = useState<AiNurturingList>([]);
    useEffect(() => {
      setAiRedialGroups(nurturingList);
    }, [nurturingList]);
    

    const handleUserSelectChange = useCallback(
      (event: SelectChangeEvent<string[]>) => {
        const {
          target: { value },
        } = event;
        setSelectedUsers(typeof value === "string" ? value.split(",") : value);
      },
      [setSelectedUsers],
    );

    const getMenuItemStyles = (
      name: string,
      selectedUsers: readonly string[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      theme: any,
    ) => {
      return {
        fontWeight:
          selectedUsers.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    };

    const handleChangeRadioButton = useCallback((key: string) => {
      setSelectedCallGroup(key);
    }, []);

    // 選択された全てのグループに対してコールプロセスを作成
    const handleCall = useCallback(async () => {
      
      try {
        const selectedCallGroup = aiRedialGroups.find(group => group.contactListId === selectedRedialGroupKey);
        
        if (!selectedCallGroup) {
          throw new Error("再コール対象が見つかりません。");
        }
        
        const selectedCallGroupContactList = await fetchContactList(selectedCallGroup.contactListId, selectedCallGroup.scriptId, selectedCallGroup.count, 0, "createdAt", "asc");
        
        if (selectedCallGroupContactList.contacts.length === 0) {
          throw new Error("再コール対象が見つかりません。");
        }
        
        dispatch(setLoadingBackdrop({ key: "handleCall", state: true }));
        const requestBody = {
          name: "NURTURING CALL",
          from: phoneNumber,
          contactIds: selectedCallGroupContactList.contacts.map((call) => call.id),
          scriptId: selectedCallGroup.scriptId,
          maxConcurrency: Number(formValues.callAiNum),
          assigneeIds: selectedUsers,
        };
        console.log("requestBody", requestBody)

        const res = await request({
          path: "/call_batches",
          httpMethod: "post",
          params: {
            body: requestBody,
          },
        });

        console.info("RedialAiCall", res);
        dispatch(
          setSnackbar({
            text: "コールを開始しました。",
            open: true,
            severity: "success",
          }),
        );

        reset();
        setFormValues({
          callAiNum: 0,
        })
        setSelectedUsers([]);
        setPhoneNumber("");
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "エラーが発生しました。",
            open: true,
            severity: "error",
          }),
        );
      } finally {
        dispatch(setLoadingBackdrop({ key: "handleCall", state: false }));
      }
    }, [selectedRedialGroupKey, aiRedialGroups, dispatch, formValues.callAiNum, phoneNumber, selectedUsers]);

    return (
      <Box
        sx={{
          mx: "auto",
          position: "relative",
          display: "inline-block",
          width: 722,
        }}
      >
        <Stack direction="row" mb={2}>
          <HistoryToggleOffOutlined sx={{ mr: 1 }}></HistoryToggleOffOutlined>
          <Typography textAlign="left" fontWeight="600" fontSize="1.1rem">
            AIナーチャリングリスト
          </Typography>
        </Stack>
        <Stack
          mb={3}
          sx={{ opacity: 0.6, textAlign: "left", fontSize: "0.9rem" }}
        >
          <Stack direction="row">
            <Box minWidth={128}>再コール対象:</Box>
            <Box>
              {companyConfig.callResultsToAiRedial
                .map((result) => callResultLabels[result])
                .join(", ")}
            </Box>
          </Stack>

          <Stack direction="row">
            <Box minWidth={128}>再コール頻度:</Box>
            <Box>
              {Object.entries(companyConfig.redialIntervalDays)
                // .filter(([key, ]) => companyConfig.callResultsToAiRedial.includes(key as any))
                .map(([key, value]) => `${callResultLabels[key]}: ${value}日`)
                .join(", ")}
            </Box>
          </Stack>

          <Stack direction="row">
            <Box minWidth={128}>最大再コール回数:</Box>
            <Box>
              {Object.entries(companyConfig.redialMaxAttempts)
                // .filter(([key, ]) => companyConfig.callResultsToAiRedial.includes(key as any))
                .map(([key, value]) => `${callResultLabels[key]}: ${value}回`)
                .join(", ")}
            </Box>
          </Stack>
        </Stack>
        <Stack position="relative">
          <Box>
            <RadioGroup>
              {aiRedialGroups.map((group,index) => (
                <RedialAccordion
                  key={index}
                  columns={columns}
                  group={group}
                  checked={selectedRedialGroupKey === group.contactListId}
                  handleChangeRadioButton={handleChangeRadioButton}
                  fetchContactList={fetchContactList}
                  
                ></RedialAccordion>
              ))}
            </RadioGroup>
            {Object.entries(aiRedialGroups).length === 0 && (
              <Box py={4}>
                <Typography sx={{ opacity: 0.5 }}>(該当なし)</Typography>
              </Box>
            )}
          </Box>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <Stack gap={2}>
                <Box display="flex" alignContent="center">
                  <PhoneOutlined
                    sx={{ mr: 1 }}
                    fontSize="small"
                  ></PhoneOutlined>
                  <Typography textAlign="left" fontSize="0.85rem">
                    コール設定
                  </Typography>
                </Box>
                <FormControl size="small" fullWidth>
                  <InputLabel>発信番号</InputLabel>
                  <Select
                    size="small"
                    sx={{ bgcolor: "#fff" }}
                    labelId="user"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  >
                    {companyPhoneNumbers?.map((phoneNumber) => (
                      <MenuItem value={phoneNumber} key={phoneNumber}>
                        {phoneNumber.replace(/^\+81/, "0")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Controller
                  name="callAiNum"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ bgcolor: "#fff" }}
                      fullWidth
                      size="small"
                      label="コールAI数"
                      error={"callAiNum" in errors}
                      helperText={errors.callAiNum?.message || ""}
                      value={formValues.callAiNum} // ここでフォームの値を表示
                      onChange={(e) => {
                        const newValue = e.target.value;
                        handleFormChange("callAiNum", newValue);
                        field.onChange(newValue);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Box display="flex" alignContent="center">
                  <PersonOutlined
                    sx={{ mr: 1 }}
                    fontSize="small"
                  ></PersonOutlined>
                  <Typography textAlign="left" fontSize="0.85rem">
                    割り当てユーザー
                  </Typography>
                </Box>
                <FormControl sx={{ mx: "auto", mt: 2 }} size="small" fullWidth>
                  <Select
                    multiple
                    size="small"
                    labelId="user"
                    value={selectedUsers}
                    onChange={handleUserSelectChange}
                    sx={{ minHeight: 100, bgcolor: "#fff" }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={users[value].name} />
                        ))}
                      </Box>
                    )}
                  >
                    {waitingUsers.map((user) => (
                      <MenuItem
                        key={user.name}
                        value={user.id}
                        style={getMenuItemStyles(
                          user.name,
                          selectedUsers,
                          theme,
                        )}
                      >
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Button
            size="large"
            sx={{ maxWidth: 300, mt: 4, mx: "auto" }}
            startIcon={<CallOutlined />}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              (!roles.includes("ADMIN") && !roles.includes("SV")) ||
              !selectedUsers.length ||
              !phoneNumber ||
              !formValues.callAiNum ||
              selectedRedialGroupKey === null
            }
            onClick={handleCall}
          >
            再コール開始
          </Button>
        </Stack>
      </Box>
    );
  },
);

export default RedialAi;
